.animated-text {
  position: relative;
  white-space: nowrap;
  display: block;

  &__wrapper {
    overflow: hidden;
    display: block;

    .animated-text--unwrapped & {
      overflow: visible;
      perspective: 600px;
    }
  }

  &__row {
    display: block;
    transform: translate3d(0, 110%, 0);
    will-change: transform;

    .animated-text--unwrapped & {
      transform-style: preserve-3d;
      transform-origin: center top;
      transform: translateY(100%) rotateX(-0deg);
      opacity: 0;
      will-change: transform, opacity;
    }

    .animated-text--enter-top & {
      transform: translate3d(0, -110%, 0);
    }

    .animated-text--in-view &,
    .travel-carousel--in-view & {
      body:not(.enter):not(.exit) & {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    .animated-text--unwrapped.animated-text--in-view &,
    .travel-carousel--in-view & {
      body:not(.enter):not(.exit) & {
        opacity: 1;
        transform: translateY(0) rotateX(0deg);
      }
    }
  }
}

.animated-text--in-view.animated-text--out-top .animated-text__row {
  body:not(.enter):not(.exit) & {
    transform: translate3d(0, -110%, 0);
  }
}
