.napulitano {
  z-index: 1;
  transform-origin: center bottom;
  transform: scale(1);
  will-change: transform;

  .scale-down & {
    transition: transform var(--transition-duration--more-slow)
      var(--transition-ease--in-out-quint);
    transform: scaleX(0.9) scaleY(0.97);

    @media (--laptop) {
      transform: scale(0.96);
    }
  }

  &--pickering {
    .dictionary__letters {
      filter: blur(8px);
      opacity: 0.4;
    }
  }
}
