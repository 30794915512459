[data-icon] {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &[data-icon="app-store"] {
    width: 120px;
    height: 40px;
    background-image: url(/assets/icons/app_store_black.svg);
    display: block;
  }
}
