.dictionary {
  width: var(--columns-3);
  padding: var(--margin) 0;
  margin-left: calc(var(--margin) + var(--full-column));
  box-sizing: border-box;

  @media (--laptop) {
    width: 100%;
    margin-left: 0;
    padding-left: calc(var(--margin) + (2 * var(--full-column)));
  }

  &__letters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 6.4rem;

    @media (--laptop) {
      max-width: var(--columns-4);
    }

    &__letter {
      &__words {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 3.2rem;

        &__word {
        }
      }
    }
  }
}
