nav {
  position: fixed;
  right: var(--margin);
  bottom: var(--margin);

  ul {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    column-gap: var(--column);
  }
}
