.text-mask-background {
  width: fit-content;
  color: transparent;
  background-image: linear-gradient(to top, var(--red), var(--red)),
    linear-gradient(to top, var(--black), var(--black));
  background-repeat: no-repeat;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 100% 100%;
  /*-webkit-text-stroke: 1px var(--red);*/
}
