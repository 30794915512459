.letter-picker {
  width: var(--columns-1);
  margin-right: var(--gutter);
  position: fixed;
  left: var(--margin);
  top: var(--margin);
  z-index: 1;

  @media (--laptop) {
    margin-right: calc((2 * var(--gutter)) + var(--columns-1));
  }

  &__letters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 36px;

    &__letter {
    }
  }
}
