:root {
  --red: #fb4f1c;
  --white: #ffffff;
  --black: #1c1c1c;
}

.red {
  color: var(--red);
}

.white {
  color: var(--white);
}

.black {
  color: var(--black);
}
