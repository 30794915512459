.dictionary-picker {
  position: fixed;
  right: var(--margin);
  top: var(--margin);
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &__left {
    .dictionary-picker--right & {
      transform: translateY(-50%);
    }

    p {
      text-align: right;

      &:first-child {
        .dictionary-picker--left & {
          opacity: 1;
        }
      }

      &:last-child {
        .dictionary-picker--right & {
          opacity: 1;
        }
      }
    }
  }

  &__right {
    margin-left: 0.4rem;

    .dictionary-picker--left & {
      transform: translateY(-50%);
    }

    p {
      &:first-child {
        .dictionary-picker--right & {
          opacity: 1;
        }
      }

      &:last-child {
        .dictionary-picker--left & {
          opacity: 1;
        }
      }
    }
  }

  &__left,
  &__right {
    transition: transform var(--transition-duration--fast)
      var(--transition-ease--out-quint);
  }

  &__letters {
    p {
      opacity: 0;
      transition: opacity var(--transition-duration--fast)
        var(--transition-ease--out-quint);
    }
  }
}
