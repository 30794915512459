.about {
  width: 100%;
  height: calc(var(--dvh, 1vh) * 100);
  box-sizing: border-box;
  padding: 0 var(--margin);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  pointer-events: all;
  opacity: 1;
  transition: opacity 0s;
  z-index: 2;

  @media (--laptop) {
    padding: 0 0 0 calc(var(--margin) + (2 * var(--full-column)));
    color: var(--white);
  }

  &--hidden {
    transition-delay: var(--transition-duration--slower);

    @media (--laptop) {
      pointer-events: none;
      opacity: 0;
    }
  }

  .animated-text {
    &:not(.animated-text--in-view) {
      .animated-text__row {
        @media (max-width: 1023px) {
          transform: none;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 2;

    &__description {
      @media (--laptop) {
        max-width: var(--columns-6);
      }

      &__subdescription {
        margin-top: 1.6rem;

        @media (--laptop) {
          margin-top: 3.2rem;
        }
      }

      &__cta {
        margin-top: 4rem;

        @media (--laptop) {
          margin-top: 5.6rem;
        }
      }
    }

    &__close {
      position: fixed;
      bottom: var(--margin);
      right: var(--margin);
      opacity: 0;
      pointer-events: none;

      @media (--laptop) {
        opacity: 1;
        pointer-events: inherit;
      }
    }

    &__contact {
      position: fixed;
      right: var(--margin);
      bottom: var(--margin);

      @media (--laptop) {
        right: auto;
        left: var(--margin);
      }
    }
  }

  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 0;
    transition: transform var(--transition-duration--more-slow)
      var(--transition-ease--in-out-quint);
    transform-origin: center bottom;
    transform: scaleY(1);

    @media (--laptop) {
      background-color: var(--black);
    }
  }

  &::after {
    z-index: 1;
    transition: transform var(--transition-duration--slower)
      var(--transition-ease--in-out-quint);
  }

  &--hidden {
    &::before,
    &::after {
      transform-origin: center top;

      @media (--laptop) {
        transform: scaleY(0);
      }
    }

    &::before {
      transition-duration: var(--transition-duration--slower);
    }

    &::after {
      transition-duration: var(--transition-duration--more-slow);
    }
  }
}
