:root {
  --gutter: 0.8rem;
  --margin: 2rem;
  --column: calc((100vw - (5 * var(--gutter)) - (2 * var(--margin))) / 6);
  --full-column: calc(var(--column) + var(--gutter));

  --columns-1: calc((1 * var(--column)));
  --columns-2: calc((2 * var(--column)) + (1 * var(--gutter)));
  --columns-3: calc((3 * var(--column)) + (2 * var(--gutter)));
  --columns-4: calc((4 * var(--column)) + (3 * var(--gutter)));
  --columns-5: calc((5 * var(--column)) + (4 * var(--gutter)));
  --columns-6: calc((6 * var(--column)) + (5 * var(--gutter)));
  --columns-7: calc((7 * var(--column)) + (6 * var(--gutter)));
  --columns-8: calc((8 * var(--column)) + (7 * var(--gutter)));
  --columns-9: calc((9 * var(--column)) + (8 * var(--gutter)));
  --columns-10: calc((10 * var(--column)) + (9 * var(--gutter)));
  --columns-11: calc((11 * var(--column)) + (10 * var(--gutter)));
  --columns-12: calc((12 * var(--column)) + (11 * var(--gutter)));

  --light-green: #ebefe8;
  --dark-green: #343a2e;
  --yellow: #f6d346;
}

@media (min-width: 1025px) {
  :root {
    --gutter: 2.4rem;
    --margin: 4rem;
    --column: calc((100vw - (11 * var(--gutter)) - (2 * var(--margin))) / 12);
  }

  html {
    font-size: 0.6944444444vw;
  }
}
