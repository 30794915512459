html {
  font-size: 2.6666666667vw;

  @media (--laptop) {
    font-size: 0.7874015748vw;
  }
}

body {
  background-color: var(--white);
  color: var(--black);
  -webkit-font-smoothing: antialiased;

  &.scale-down {
    background-color: var(--black);
  }
}

* {
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none !important;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}
