:root {
  --font-body: "Cormorant Garamond", serif;
}

body {
  font-family: var(--font-body);
}

.p1 {
  font-size: 1.4rem;

  @media (--laptop) {
    font-size: 2rem;
  }
}

.h2 {
  font-size: 3.2rem;

  @media (--laptop) {
    font-size: 6.6rem;
  }
}

.light {
  font-weight: 300;
}

.bold {
  font-weight: 700;
}
