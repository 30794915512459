main {
  height: calc(var(--dvh, 1vh) * 100);
  margin: 0;
  align-items: flex-start;
  background-color: var(--white);
  display: none;

  @media (--laptop) {
    display: flex;
  }
}
